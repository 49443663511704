export interface APIPermissionViewModel {
    Id: number;
    Name: string;
    Description: string;
    RoleTypePermissions: RoleTypePermission[];
}

export interface RoleTypePermission {
    RoleTypeId: number;
    PermissionId: number;
}

export const APIPermissions = [
    'VIEW_CONFIGURABLE_JSRULES_MAPPING',
    'CREATE_UPDATE_DELETE_CONFIGURABLE_JSRULES_MAPPING',
    'RUN_DASHBOARD_REPORT',
    'CREATE_WORKFLOW_DOCUMENT',
    'UPLOAD_FEEDS',
] as const;

export type APIPermission = typeof APIPermissions[number];
