import { HotTable, HotTableClass } from '@handsontable/react';
import { registerAllModules } from 'handsontable/registry';
import 'handsontable/dist/handsontable.min.css';
import 'pikaday/css/pikaday.css';
import { forwardRef, useImperativeHandle, useRef } from 'react';
import { ColumnSettings } from 'handsontable/settings';
import { CellValue } from 'handsontable/common';

registerAllModules();

export interface HotTableComponentProps {
    columns: ColumnSettings[];
    colHeaders: string[];
    setDataValidity: (valid: boolean) => void;
    colWidths?: number[];
    setHotChanges?: (tableChange: HotTableChangeInterface) => void;
    setData?: (data: CellValue[] | undefined) => void;
}

export interface HotTableChangeInterface {
    changes: CellValue[] | null;
    source: string;
}

/**
 * HotTableComponent is a wrapper around the Handsontable component.
 * It provides a controlled interface to the Handsontable instance.
 * It also provides a callback to notify the parent component of the data validity.
 * @param props.columns - column settings for the Handsontable instance
 * @param props.colHeaders - column headers for the Handsontable instance
 * @param props.colWidths - column widths for the Handsontable instance
 * @param props.setDataValidity - callback to notify the parent component of the data validity
 * @param props.setHotChanges - callback to notify the parent component of the data changes
 * @param props.setData - callback to notify the parent component of the data
 * @param forwardedRef - ref to the HotTableClass instance
 */
export const HotTableComponent = forwardRef<HotTableClass, HotTableComponentProps>(function HotTableComponent(
    props,
    forwardedRef
) {
    const hotRef = useRef<HotTableClass | null>(null);
    useImperativeHandle(forwardedRef, () => hotRef.current as HotTableClass);

    const validate = () => {
        hotRef.current?.hotInstance?.validateCells((valid) => {
            props.setDataValidity(valid);
        });
    };

    return (
        <HotTable
            ref={hotRef}
            colWidths={props.colWidths}
            colHeaders={props.colHeaders}
            hiddenColumns={{
                indicators: true,
            }}
            contextMenu={true}
            multiColumnSorting={true}
            filters={true}
            rowHeaders={true}
            autoWrapCol={true}
            autoWrapRow={true}
            manualRowMove={true}
            licenseKey="non-commercial-and-evaluation"
            columns={props.columns}
            afterChange={(changes, source) => {
                props.setHotChanges?.({ changes, source });
                props.setData?.(hotRef.current?.hotInstance?.getData());
                validate();
                hotRef.current?.hotInstance?.render();
            }}
            minSpareRows={1}
        />
    );
});
